import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { deviceInfo } from '../../deviceinfo'
import { backendUrl } from '../../constants'

export const fetchRegister = createAsyncThunk(
   'user/fetchRegister',
   async ({ phone, lng }, { signal }) => {
      const response = await fetch(
         `${backendUrl}/register`,
         {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
               phone,
               deviceInfo: {
                  ...deviceInfo,
                  locale: lng
               }
            }),
            signal
         }
      )
      const result = await response.json()

      return result.responseCode
   }
)

export const fetchLogin = createAsyncThunk(
   'user/fetchUserLogin',
   async ({ lng }, { signal }) => {
      const responseLogin = await fetch(
         `${backendUrl}/login`,
         {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
               deviceInfo: {
                  ...deviceInfo,
                  locale: lng
               },
               locale: lng
            }),
            signal
         }
      )
      const resultLogin = await responseLogin.json()

      return {
         responseCode: resultLogin.responseCode,
         user_info: resultLogin.data,
         fillProfile: false
      }
   }
)

export const fetchConfirm = createAsyncThunk(
   'user/fetchConfirm',
   async (code, { signal }) => {
      const responseConfirm = await fetch(
         `${backendUrl}/confirm`,
         {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ code }),
            signal
         }
      )
      const result = await responseConfirm.json()

      const isSuccess = result.responseCode === 0
      return {
         needFillProfile: result?.data?.need_to_fill_profile || false,
         isConfirmed: isSuccess,
         isCodeError: !isSuccess,
         messageError: !isSuccess ? result.responseMessage : ''
      }
   }
)

export const fetchLogout = createAsyncThunk(
   'user/fetchLogout',
   async (_, { signal }) => {
      const logoutResponse = await fetch(
         `${backendUrl}/logout`,
         {
            method: 'POST',
            credentials: 'include',
            signal
         }
      )
      const logoutResult = await logoutResponse.json()
      return logoutResult
   }
)

export const fetchOrders = createAsyncThunk(
   'user/fetchOrders',
   async (params, { signal }) => {
      const responseOrders = await fetch(
         `${backendUrl}/v2/orders?${new URLSearchParams({
            filter: 'actual',
            limit: 5,
            ...params
         })}`,
         {
            method: 'GET',
            credentials: 'include',
            signal
         }
      )

      const { data } = await responseOrders.json()
      return { orders: data.orders }
   }
)

export const fetchOrdersLast = createAsyncThunk(
   'user/fetchOrdersLast',
   async (offset) => {
      const responseOrders = await fetch(
         `${backendUrl}/v2/orders?${new URLSearchParams({
            filter: 'actual',
            limit: 5,
            offset
         })}`,
         {
            method: 'GET',
            credentials: 'include',
            refresh: true
         }
      )

      const { data } = await responseOrders.json()
      return { orders: data.orders }
   }
)

export const fetchUserProfile = createAsyncThunk(
   'user/fetchUserProfile',
   async (_, { signal }) => {
      const responseProfile = await fetch(
         `${backendUrl}/profile`,
         {
            method: 'GET',
            credentials: 'include',
            signal
         }
      )

      const resultProfile = await responseProfile.json()
      return resultProfile
   }
)

export const fillUserProfile = createAsyncThunk(
   'user/fillUserProfile',
   async (info, { signal }) => {
      const registerConfirm = await fetch(
         `${backendUrl}/profile`,
         {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(info),
            signal
         }
      )

      const registerConfirmResult = await registerConfirm.json()
      return {
         responseCode: registerConfirmResult.responseCode,
         info
      }
   }
)

const initialState = {
   login: {
      isCode: false,

      isConfirmed: false,
      isCodeError: false,
      messageError: '',
      needFillProfile: false,

      isLogined: false,

      isLoading: false
   },

   user: {},

   orders: {
      data: [],
      isLoading: false,
      isLast: false
   }
}

const userSlice = createSlice({
   name: 'user',
   initialState,
   extraReducers: {
      [fetchLogin.fulfilled]: (state, { payload }) => {
         state.login.isLoading = false
         if (payload.responseCode === 0) {
            state.user = payload.user_info
            state.login.needFillProfile = !state.user.first_name || !state.user.last_name || !state.user.email
            state.login.isCode = false
            state.login.isLogined = true
         }
      },
      [fetchLogin.pending]: (state, { meta }) => {
         if (!meta.arg?.refresh) state.login.isLoading = true
      },

      [fillUserProfile.fulfilled]: (state, { payload }) => {
         if (payload.responseCode === 0) {
            state.user = {
               ...state.user,
               ...payload.info
            }
         }
      },

      [fetchRegister.fulfilled]: (state, { payload }) => {
         const isSuccess = payload === 0
         state.login.isCode = isSuccess
         state.login.codeError = !isSuccess
      },

      [fetchConfirm.fulfilled]: (state, { payload }) => {
         state.login = {
            ...state.login,
            ...payload
         }
      },

      [fetchUserProfile.fulfilled]: (state, { payload }) => {
         if (payload.responseCode === 0) {
            state.login.isLogined = true
            state.user = payload.data
            state.login.needFillProfile = !state.user.first_name || !state.user.last_name || !state.user.email
         }

         state.login.isLoading = false
      },
      [fetchUserProfile.pending]: (state) => {
         state.login.isLoading = true
      },

      [fetchLogout.fulfilled]: (_, { payload }) => payload.responseCode === 0 && initialState,

      [fetchOrders.fulfilled]: (state, { payload }) => {
         state.orders.data = payload.orders
         state.orders.isLoading = false
         if(payload.orders.length < 5) {
            state.orders.isLast = true
         }
      },
      [fetchOrders.pending]: (state) => {
         state.orders.isLoading = true
      },
      [fetchOrdersLast.fulfilled]: (state, { payload }) => {
         state.orders.data = [...state.orders.data, ...payload.orders]
         if(payload.orders.length < 5) {
            state.orders.isLast = true
         }
      },
   }
})
export const { resetUser } = userSlice.actions
export default userSlice.reducer
