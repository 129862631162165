import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { fetchAirportsList } from './redux/slices/airportsSlice'
import { fetchUserCart } from './redux/slices/cartSlice'
import { fetchLogin, fetchUserProfile } from './redux/slices/userSlice'
import { useTranslation } from 'react-i18next'

import './App.scss'

import Footer from './components/footer/Footer'
import Navbar from './components/navbar/Navbar'
import { AppRouter } from './router/AppRouter'

function App () {
   const { i18n } = useTranslation()
   const dispatch = useDispatch()
   const { login } = useSelector((state) => state.user)
   const { isLoading, cart } = useSelector((state) => state.cart)

   useEffect(() => dispatch(fetchAirportsList(i18n.language)), [i18n.language])

   useEffect(() => {
      dispatch(fetchUserProfile()).then(() => !isLoading && dispatch(fetchUserCart(i18n.language)))
   }, [])

   useEffect(() => login.isConfirmed && dispatch(fetchLogin({ lng: i18n.language })).then(
      () => dispatch(fetchUserCart(i18n.language))
   ), [login.isConfirmed])

   return (
      <div className='wrapper'>
         <BrowserRouter>
            <Navbar />

            <AppRouter />

            <Footer />
         </BrowserRouter>
      </div>
   )
}

export default App
