import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import WA from '../../assets/images/wa.svg'
import TELEGRAM from '../../assets/images/telegram.svg'

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-12'>
            <div className='row footer-contacts d-flex justify-content-between'>
              <div className='col-lg-12 col-8'>
                <h3>{t('footer.connect')}</h3>
                <p>
                  E-mail:&nbsp;
                  <span>
                    <a href='mailto:support@mileonair.com'>
                      support@mileonair.com
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-2 col-6'>
            <h3>{t('footer.services.title')}</h3>
            <ul>
              <li>
                <Link to='/service#meet'>{t('footer.services.meet')}</Link>
              </li>
              <li>
                <Link to='/service#package'>{t('footer.services.pack')}</Link>
              </li>
              <li>
                <Link to='/service#busines'>
                  {t('footer.services.busines')}
                </Link>
              </li>
              <li>
                <Link to='/service'>
                  {t('footer.services.transfer')}
                </Link>
              </li>
            </ul>
          </div>

               <div className='col-lg-2 col-6'>
                  <h3>{t('footer.menu')}</h3>
                  <ul>
                     <li>
                        <Link to='/partners'>{t('footer.partners')}</Link>
                     </li>
                  </ul>
               </div>

               <div className='col-lg-4 col-12'>
                  <p className='copy'>
                     &copy;<Link to='/'>{t('footer.copyright.part1')}</Link>,<br></br>
                     {t('footer.copyright.part2')}
                     <br></br>
                     {t('footer.copyright.part3')}
                     <br></br>
                     {t('footer.copyright.part4')}
                     <br></br>
                     {t('footer.copyright.part5')}
                  </p>
               </div>
            </div>

            <div
               className='up'
               onClick={() => window.scrollTo(0, 0)}
            >
               {t('footer.up')}
            </div>
         </div>
      </footer>
   )
}
