import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import { storePersist, persistor } from './redux/store-persist'
import './i18n'

ReactDOM.render(
   <React.StrictMode>
      <Provider store={storePersist}>
         <Suspense fallback={<div>Загрузка...</div>}>
            <PersistGate loading={<div></div>} persistor={persistor}>
               <App />
            </PersistGate>
         </Suspense>
      </Provider>
   </React.StrictMode>,
   document.getElementById('root')
)
